@import "base.scss";

.loading{
    position:fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    height: 100vh;
    width: 100%;
    z-index: 99;
    background: #c7d8e3;
    display: grid;
    place-items: center;
    font-family: "Orbitron", sans-serif;
    color: #111;
    display: none;

    &__box{
        position: relative;
        width: 1000px;
        height: 300px;
        border: 3px solid #82a2be;
        border-top: 3px solid #5e778a;
        border-bottom: 3px solid #5e778a;
        @media (max-width: 1200px) {
            &{
                width: 90vw;
            }           
        }
    }

    &__bar{
        width: 90%;
        height: 10px;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translateX(-50%);
        background: none;
        border-radius: 2px;
        &--inner {
            height: 100%;
            width: 0%;
            border-radius: 2px;
        background: rgb(51, 53, 75);
        }
    }

    &__text{
        position: relative;
        color: #111;
        padding: 1rem;
        font-size: 25px;
        font-weight: 700;
        display: flex;
        align-items:center;
        justify-content: center;
        
        @media (max-width: 700px) {
            &{
                font-size: 20px;
            }
        }

        &--dot{
            width: 20px;
            height: 20px;
            margin: 0 3px;
            border-radius: 50%;
            background: #111;
            animation: pulse 1s infinite;

            @media (max-width: 700px) {
                &{
                    width: 16px;
                    height: 16px;
                }
            }

            @keyframes pulse {
                from{
                    opacity: 0;
                    background: #c7d8e3;
                }
                to{
                    opacity: 1;
                }
            }
        }

        &--border{
            width: 90%;
            height: 1px;
            background: #82a2be;
            position: absolute;
            bottom: 0;
            left: 50%;
            transform: translateX(-50%);
        }
    }

    &__counter{
        position: absolute;
        top: 70%;
        left: 0;
        color: #444;
        font-size: 20px;
        font-weight: 700;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 10px;
        &--number{
            color: #444;
        }
    }

    &__svg {
        position: absolute;
        width: 115%;
        opacity: 0;
    }
}