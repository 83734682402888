/*about*/

.container {
    position:relative;
    background: #b3cadc;
    color: #25384b;
    height: 37%;
    width: 46%;
    margin-left: 5%;
    margin-right: 3%;
    margin-top: 3.4%;
    margin-bottom: 7%;
    padding: 3%;
    padding-top: 3%;
    font-size: 19px;
    border-radius: 9px;
    z-index: 0;
  }

  @media (max-width: 1600px){
    .container {
      height: 37%;
      width: 46%;
      margin-left: 5%;
      margin-right: 3%;
      margin-top: 3.4%;
      margin-bottom: 7%;
      padding: 3%;
      padding-top: 4%;
      font-size: 17px;
      border-radius: 9px;
    }
  }

  @media (max-width: 1180px){
    .container {
      height: 37%;
      width: 46%;
      margin-left: 5%;
      margin-right: 3%;
      margin-top: 3%;
      margin-bottom: 7%;
      padding: 3%;
      padding-top: 6%;
      font-size: 13px;
      border-radius: 7px;
    }
  }

  @media (max-width: 768px){
    .container {
      height: 37%;
      width: 89%;
      margin-left: 6%;
      margin-right: 6%;
      margin-top: 3%;
      margin-bottom: 7%;
      padding: 3%;
      padding-top: 8%;
      font-size: 14px;
      border-radius: 5px;
    }
  }

  @media (max-width: 390px){
    .container {
        height: 95%;
        width: 89%;
        margin-left: 5%;
        margin-top: 30px;
        margin-bottom: 0%;
        padding: 19px;
        padding-top: 8%;
        font-size: 11px;
        border-radius: 5px;
    }
  }

/*images*/ 
.container2 {
    position:relative;
    display: flex;
    height:848px;
    margin-bottom: 50px;
    margin-left: 2%;
    margin-right:auto;
    margin-top: 5%;
    background: none;
    color: #25384b;
  }

  @media (max-width: 1350px){
    .container2 {
      height:511px;
    }
  }

  @media (max-width: 700px){
    .container2 {
      height:411px;
        margin-left:30px;
        margin-top: 0%;
    }
  }

/*resume*/
.container3 {

  width: 55vw;
  color: #25384b;
  background: #b3cadc;
  border-radius: 9px;
  margin: 4vw 3vw 0vw 3vw ;
  padding: 3vw 3vw 2vw 3vw;
  font-size: .8vw;
  position: relative;
}

@media (max-width: 1600px) {
  .container3 {
    width: 60%;
    border-radius: 9px;
    margin-top: 6.3%;
    margin-left: 4.3%;
    margin-right: auto;
    margin-bottom: 50;
    padding: 3% 5% 4% 5%;
    font-size: 18px;
  }
}

@media (max-width: 1366px) {
  .container3 {
    width: 76%;
    border-radius: 7px;
    margin-top: 10%;
    margin-bottom: 50px;
    margin-left: 7%;
    padding: 3% 3% 5% 3%;
    font-size: 16px;
  }
}

@media (max-width: 1180px) {
  .container3 {
    width: 86%;
    border-radius: 7px;
    margin-top: 10%;
    margin-bottom: 50px;
    margin-left: 7%;
    padding: 3% 3% 5% 3%;
    font-size: 15px;
  }
}

@media (max-width: 768px) {
  .container3 {
    width: 90%;
    border-radius: 5px;
    margin-top: 11vw;
    margin-left: 5%;
    padding: 10vw 6vw 3vw 8vw;
    font-size: 13px;
  }
}

@media (max-width: 390px) {
  .container3 {
    width: 90%;
    border-radius: 7px;
    margin-top: 17vw;
    margin-bottom: 35%;
    margin-left: 5%;
    padding: 10vw 6vw 1vw 8vw;
    font-size: 11px;
  }
}

/*portfolio*/
.container4 {
  position:relative;
  display: flex;
  height:750px;
  width:1000px;
  margin-left: 5%;
  margin-right:5%;
  margin-top: 5%;
  background: #212a3a;
  color: #c7d8e3;
  font-size: 19px;
  padding: 5%;
  border-radius: 9px;
  border-color: #01101f;
  z-index: 1;
}

@media (max-width: 1600px){
  .container4 {
      height:950px;
      width:800px;
      font-size: 17px;
      padding: 45px;
      margin-left: 5%;
      margin-right:150px;
      margin-top: 150px;
      border-radius: 9px;
  }
}

@media (max-width: 1180px){
  .container4 {
      height: 420px;
      width: 590px;
      font-size: 13px;
      padding: 22px;
      margin-left: 5%;
      margin-right:10px;
      margin-top: 90px;
      margin-bottom: 100px;
      border-radius: 7px;
  }
}

@media (max-width: 768px){
  .container4 {
      height: 450px;
      width: 700px;
      font-size: 14px;
      padding: 48px;
      margin-left: 5%;
      margin-right:10px;
      margin-top: 80px;
      margin-bottom: 15%;
      padding-bottom: 0px;
      border-radius: 5px;
  }
}

@media (max-width: 390px){
  .container4 {
      height: 280px;
      width: 89%;
      font-size: 11px;
      padding: 19px;
      margin-left: 5%;
      margin-top: 85px;
      margin-bottom: 20%;
      border-radius: 5px;
  }
}


/* for images */
.fitit {
  height:100%;
  margin-top: 50px;
  padding-left: 20px;
}

@media (max-width: 1374px){
  .fitit {
      height: 90%;
      margin-top: 0%;
      margin-left: 10%;
      margin-right: 10%;
      padding-top: 0%;
      
  }
}

@media (max-width: 700px){
  .fitit {
      height: 70%;
      padding-top: 15%;
  }
}


/* (B) FLOAT ITEMS */
.float-wrap > * { float: left; margin-top: 20px;}

.float-wrap { overflow: auto; }
  .float-wrap::after {
    content: "";
    clear: both;
    display: table;
  }


.foliodiv{
  clear: both;
}

  /* for portfolio images */
  .foliofitit {
    height:50%;
    width: 50%;
    margin-top: 50px;
    padding: 10px;
  }

  .contact{
    font-size: .8vw;
    font-weight: 600;
    padding-top: .5vw;
  }

  @media (max-width:1600px){
    .contact{
      font-size: 14px;
      font-weight: 600;
    }
  }

  @media (max-width:768px){
    .contact{
      font-size: 11px;
    }
  }

  @media (max-width:390px){
    .contact{
      font-size: 11px;
    }
  }

  .content{
    padding: 50px;
    font-size: 19px;
    &__undrline {
      border-bottom: solid 3px #445665;
      padding-bottom: 1%
  }
}

@media (max-width: 1350px){
  .content {
    padding: 11px;
    font-size: 16px;
    border-bottom: solid 4px #445665;
  }
}

@media (max-width: 700px){
  .content {
    padding: 15px;
    font-size: 12px;
    border-bottom: solid 3px #445665;
  }
}


.headline3{
  padding-bottom: 1%;
  font-size: 18px;
    &__addspacetop{
      padding-top: 1vw;
    }
}

@media (max-width: 1600px){
  .headline3 {
    padding-bottom: 1%;
    font-size: 16px;
  }
}

@media (max-width: 1180px){
  .headline3 {
    padding-bottom: 1%;
    font-size: 14px;
  }
}

@media (max-width: 768px){
  .headline3 {
    padding-bottom: 1%;
    font-size: 12px;
  }
}

@media (max-width: 390px){
  .headline3 {
    padding-bottom: 1%;
    font-size: 11px;
  }
}


.alinpara{
  text-align: center;
  }
  
  .txtcontent{
  padding-top: 10px;
  }
  
  .lstdeco{
  list-style: none;
  }
  
  
  .beback{
  text-align: center;
  font-size: 56px;
  font-weight: 600;
  padding: 20% 15% 0% 15% ;
  color: #0d0e20;
  }
  
  
  aside {
    width: 40%;
    padding-left: .5rem;
    margin-left: .5rem;
    float: right;
    box-shadow: inset 5px 0 5px -5px #29627e;
    font-style: italic;
    color: #29627e;
  }
  

  aside > p {
    margin: .5rem;
  }
  
  .somespace {
  padding: 0% 0% 0% 3%;
  }
  
  .bottomspace {
  padding: 0% 0% 9% 3%;
  }

.alinpara{
  text-align: center;
}

.txtcontent{
  padding-top: 10px;
}

.lstdeco{
  list-style: none;
}


.halfcurve {
  height: 38vw;
  width: 48vw;
  z-index: 1;
  background: #b3cadc;
  border-radius: 0% 50% 50% 0%;
  margin: 5.4vw 0vw 0vw 0vw;
  padding: 3vw 3vw 40vw 0vw;
  position: relative;
}

@media (max-width: 1600px) {
  .halfcurve {
    height: 38vw;
    width: 48vw;
    z-index: 1;
    background: #b3cadc;
    border-radius: 0% 50% 50% 0%;
    margin: 4.5% 0% 0%;
    padding: 3% 3% 45% 0%;
    position: relative;
  }
}

@media (max-width: 1386px) {
  .halfcurve {
    height: 35vw;
    width: 55vw;
    z-index: 1;
    background: #b3cadc;
    border-radius: 0% 50% 50% 0%;
    margin: 4.5% 0% 0%;
    padding: 3% 3% 55vw 0%;
    position: relative;
  }
}

@media (max-width: 1180px) {
  .halfcurve {
    height: 35vw;
    width: 55vw;
    z-index: 1;
    background: #b3cadc;
    border-radius: 0% 50% 50% 0%;
    margin: 5.5% 0% 0%;
    padding: 3% 3% 55% 0%;
    position: relative;
  }
}

@media (max-width: 1024px) {
  .halfcurve {
    height: 95%;
    width: 90vw;
    z-index: 1;
    background: #b3cadc;
    border-radius: 0% 0% 50% 50%;
    margin: 0% 0% 0% 5%;
    padding: 20% 0% 73%;
    position: relative;
  }
}

@media (max-width: 820px) {
  .halfcurve {
    height: 95%;
    width: 90%;
    z-index: 1;
    background: #b3cadc;
    border-radius: 0% 0% 50% 50%;
    margin: 0% 0% 0% 5%;
    padding: 20% 0% 73%;
    position: relative;
  }
}

@media (max-width: 390px) {
  .halfcurve {
    height: 200px;
    width: 90%;
    z-index: 1;
    background: #b3cadc;
    border-radius: 0% 0% 50% 50%;
    margin: 0% 0% 0% 5%;
    padding: 20% 0% 73%;
    position: relative;
  }
}

.square_extn {
  height: 38vw;
  width: 24vw;
  z-index: 1;
  background: #b3cadc;
  margin: 5.4vw 0vw 0vw 0vw;
  padding: 3vw 0vw 40vw 0vw;
  position: relative;
}

@media (max-width: 1600px) {
  .square_extn {
    height: 300px;
    width: 300px;
    z-index: 1;
    background: #b3cadc;
    margin: 4.5% 0% 30%;
    padding: 48% 0% 0%;
    position: relative;
  }
}

@media (max-width: 1386px) {
  .square_extn {
    height: 30vw;
    width: 24vw;
    z-index: 1;
    background: #b3cadc;
    margin: 4.5% 0% 30%;
    padding: 48% 0% 10vw;
    position: relative;
  }
}

@media (max-width: 1180px) {
  .square_extn {
    height: 32vw;
    width: 28vw;
    z-index: 1;
    background: #b3cadc;
    margin: 5.5% 0% 30%;
    padding: 58% 0% 0%;
    position: relative;
  }
}

@media (max-width: 1024px) {
  .square_extn {
    height: 33vw;
    width: 90vw;
    z-index: 1;
    background: #b3cadc;
    margin: 0% 0% 0% 5%;
    padding: 0% 0% 23%;
    position: relative;
  }
}

@media (max-width: 768px) {
  .square_extn {
    height: 33vw;
    width: 90vw;
    z-index: 1;
    background: #b3cadc;
    margin: 0% 0% 0% 5%;
    padding: 0% 0% 23%;
    position: relative;
  }
}

@media (max-width: 390px) {
  .square_extn {
    height: 38%;
    width: 90%;
    z-index: 1;
    background: #b3cadc;
    margin: 0% 0% 0% 5%;
    padding: 0% 0% 73%;
    position: relative;
  }
}

.halfcurvetxt {
  display: inline-flexbox;
  color: #25384b;
  height: 50vw;
  width: 53vw;
  z-index: 3;
  background: none;
  margin: 5.4% 8% 3vw 2%;
  padding: 5% 9% 0% 0%;
  font-size: .87vw;
  position: absolute;
}

@media (max-width: 1600px) {
  .halfcurvetxt {
    display: inline-flexbox;
    color: #25384b;
    height: 50vw;
    width: 57vw;
    z-index: 3;
    background: none;
    margin: 5.4% 8% 3vw 2%;
    padding: 5% 9% 0% 0%;
    font-size: 1.0vw;
    position: absolute;
  }
}

@media (max-width: 1386px) {
  .halfcurvetxt {
    display: inline-flexbox;
    color: #25384b;
    height: 20vw;
    width: 60vw;
    z-index: 3;
    background: none;
    margin: 7.4% 8% 7% 2%;
    padding: 5% 9% 0% 0%;
    font-size: 1.1vw;
    position: absolute;
  }
}

@media (max-width: 1180px) {
  .halfcurvetxt {
    display: inline-flexbox;
    color: #25384b;
    height: 20vw;
    width: 65vw;
    z-index: 3;
    background: none;
    margin: 7.4% 8% 7% 2%;
    padding: 5% 9% 0% 0%;
    font-size: 1.2vw;
    position: absolute;
  }
}

@media (max-width: 1024px) {
  .halfcurvetxt {
    display: inline-flexbox;
    color: #25384b;
    height: 4vw;
    width: 88vw;
    z-index: 3;
    background: none;
    margin: 7% 5% 0%;
    padding: 3% 7% 5%;
    font-size: 1.8vw;
    position: absolute;
  }
}

@media (max-width: 768px) {
  .halfcurvetxt {
    display: inline-flexbox;
    color: #25384b;
    height: 4vw;
    width: 90%;
    z-index: 3;
    background: none;
    margin: 7% 5% 0%;
    padding: 3% 9% 5% 7%;
    font-size: 1.9vw;
    position: absolute;
  }
}

@media (max-width: 390px) {
  .halfcurvetxt {
    display: inline-flexbox;
    color: #25384b;
    height: 4vw;
    width: 90vw;
    z-index: 3;
    background: none;
    margin: 12.4% 7% 7% 8%;
    padding: 0% 5% 0% 0%;
    font-size: 2.5vw;
    position: absolute;
  }
}

.infotitle {
  color: #25384b;
  font-size: 25px;
  font-weight: 600;
}

.infotext1 {
  color: #dbe9f3;
  padding-top: 1%;
  padding-bottom: 3%;
  font-size: 20px;
  font-weight: 400;
}

.infotext2 {
  color: #25384b;
  margin-bottom: 0%;
  padding-top: 2%;
  padding-bottom: 0%;
  font-size: 19px;
  font-weight: 600;
}

.infotext3 {
  color: #dbe9f3;
  margin-top: 0%;
  padding-top: 0%;
  font-size: 17px;
  font-weight: 400;
}

.sliderindent {
  text-indent: .5em;
}

.footalign {
  text-align: center;
  z-index: 1200;

}

.foottext {
  color: #b3cadc;
  padding-top: 11%;
  font-size: .65vw;
}

@media (min-width: 1367px){
  .foottext {
    font-size: .7vw;
    padding-top: 5.5vw;
    padding-bottom: 1vw;
  }
}

@media (max-width: 1366px){
  .foottext {
    font-size: 1vw;
    padding-top: 24vw;
    padding-bottom: .5vw;
  }
}

@media (max-width: 1180px){
  .foottext {
    font-size: 1vw;
    padding-top: 17.5vw;
    padding-bottom: 1vw;
  }
}

@media (max-width: 1024px){
  .foottext {
    font-size: 1.5vw;
    padding-top: 5vw;
    padding-bottom: 2vw;
  }
}

@media (max-width: 820px){
  .foottext {
    font-size: 1.5vw;
    padding-top: 13vw;
    padding-bottom: 2vw;
  }
}

@media (max-width: 768px){
  .foottext {
    font-size: 1.75vw;
    padding-top: 3vw;
    padding-bottom: 1vw;
  }
}

@media (max-width: 390px){
  .foottext {
    font-size: 2.5vw;
    padding-top: 68vw;
    padding-bottom: 2vw;
  }
}

.footport {
  text-align: center;
  color: #b3cadc;
  font-size: .85vw;
}


@media (max-width: 820px){
  .footport {
    text-align: center;
    color: #b3cadc;
    font-size: 2vw;
  }
  }

@media (max-width: 390px){
.footport {
  text-align: center;
  color: #b3cadc;
  font-size: 4vw;
}
}


@media (max-width: 1600px) {
  .infotitle {
    color: #25384b;
    font-size: 25px;
    font-weight: 600;
  }
}

@media (max-width: 1180px) {
  .infotitle {
    color: #25384b;
    font-size: 25px;
    font-weight: 600;
  }
}

@media (max-width: 768px) {
  .infotitle {
    color: #25384b;
    font-size: 25px;
    font-weight: 600;
  }
}

@media (max-width: 390px) {
  .infotitle {
    color: #25384b;
    font-size: 18px;
    font-weight: 600;
  }
}

@media (max-width: 1600px) {
  .infotext1 {
    color: #dbe9f3;
    padding-top: 1%;
    padding-bottom: 3%;
    font-size: 20px;
    font-weight: 400;
  }
}

@media (max-width: 1180px) {
  .infotext1 {
    color: #dbe9f3;
    padding-top: 1%;
    padding-bottom: 3%;
    font-size: 20px;
    font-weight: 400;
  }
}

@media (max-width: 768px) {
  .infotext1 {
    color: #dbe9f3;
    padding-top: 1%;
    padding-bottom: 3%;
    font-size: 20px;
    font-weight: 400;
  }
}

@media (max-width: 390px) {
  .infotext1 {
    color: #dbe9f3;
    padding-top: 1%;
    padding-bottom: 3%;
    font-size: 15px;
    font-weight: 400;
  }
}

@media (max-width: 1600px) {
  .infotext2 {
    color: #25384b;
    margin-bottom: 0%;
    padding-top: 2%;
    padding-bottom: 0%;
    font-size: 19px;
    font-weight: 600;
  }
}

@media (max-width: 1180px) {
  .infotext2 {
    color: #25384b;
    margin-bottom: 0%;
    padding-top: 2%;
    padding-bottom: 0%;
    font-size: 19px;
    font-weight: 600;
  }
}

@media (max-width: 768px) {
  .infotext2 {
    color: #25384b;
    margin-bottom: 0%;
    padding-top: 2%;
    padding-bottom: 0%;
    font-size: 19px;
    font-weight: 600;
  }
}

@media (max-width: 390px) {
  .infotext2 {
    color: #25384b;
    margin-bottom: 0%;
    padding-top: 2%;
    padding-bottom: 0%;
    font-size: 14px;
    font-weight: 600;
  }
}

@media (max-width: 1600px) {
  .infotext3 {
    color: #dbe9f3;
    margin-top: 0%;
    padding-top: 0%;
    font-size: 17px;
    font-weight: 400;
  }
}

@media (max-width: 1180px) {
  .infotext3 {
    color: #dbe9f3;
    margin-top: 0%;
    padding-top: 0%;
    font-size: 17px;
    font-weight: 400;
  }
}

@media (max-width: 768px) {
  .infotext3 {
    color: #dbe9f3;
    margin-top: 0%;
    padding-top: 0%;
    font-size: 17px;
    font-weight: 400;
  }
}

@media (max-width: 390px) {
  .infotext3 {
    color: #dbe9f3;
    margin-top: 0%;
    padding-top: 0%;
    font-size: 12px;
    font-weight: 400;
  }
}


.inprogress{
  font-size: .7vw;
  font-weight: 500;
  //text-align: center;
  padding: 0vw 0vw 0vw 5vw;
}

@media (max-width: 1600px){
  .inprogress {
    font-size: 1vw;
    font-weight: 500;
  }
}

@media (max-width: 1180px){
  .inprogress {
    font-size: 1.4vw;
    font-weight: 500;
    padding: 0vw 0vw 0vw 10.5vw;
  }
}

@media (max-width: 768px){
  .inprogress {
    font-size: 1.6vw;
    font-weight: 500;
    padding: 0vw 0vw 0vw 6.3vw;
  }
}

@media (max-width: 390px){
  .inprogress {
    font-size: .7vw;
    font-weight: 500;
  }
}


.thanks{
  font-size: .7vw;
  font-weight: 500;
  //text-align: center;
  padding: 0vw 0vw 0vw 5vw;
}

@media (max-width: 1600px){
  .thanks {
    font-size: 1vw;
    font-weight: 500;
  }
}

@media (max-width: 1180px){
  .thanks {
    font-size: 1.4vw;
    font-weight: 500;
    padding: 0vw 0vw 0vw 10.5vw;
  }
}

@media (max-width: 768px){
  .thanks {
    font-size: 1.6vw;
    font-weight: 500;
    padding: 0vw 0vw 0vw 6.3vw;
  }
}

@media (max-width: 390px){
  .thanks {
    font-size: .7vw;
    font-weight: 500;
  }
}

.centertext {
  text-align: center;
}