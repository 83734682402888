.aniLinks {
    position: relative;
    display: grid;
    place-items: center;
    overflow: hidden;
    &:before {
        content: "";
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 2px;
        background: currentColor;
        transform: scale3d(0, 1, 1);
        transform-origin: right;
        transition: transform 0.4s ease-in-out;
    }
    &:after {
        content: attr(data-text);
        position: absolute;
        top: 0;
        height: 100%;
        transform: translate3d(150%, 0, 0);

    }
    &:hover:before {
        transform: scale3d(1, 1, 1);
        transform-origin: left;
    }

    @media  (max-width: 800px) {
        & {
            font-size: 14px;
        }
    }
}

.aniButton{
    position: relative;
    border: none;
    cursor: pointer;
    width: 120px;
    height: 55px;
    background: none;
    color: #fff;
    padding-right: 15px;
    &:before,
    &:after{
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }
    &:before {
        content: "";
        background: #fff;
        border-radius: 68% 32% 67% 33% / 61% 40% 60% 39% ;
        transition: clip-path 0.5s;
    }
    @media  (max-width: 800px) {
        & {
            transform: scale3d(0.7, 0.7, 0.7);
            font-size: 14px;
        }
    }
}
.contactButton{
    background:#fff;
    color: #fff;
    border: none;
    cursor: pointer;
    width: 100px;
    height: 30px;
    font-size: 18px;
    padding-right: 15px;
    @media  (max-width: 300px) {
        & {
            transform: scale3d(0.7, 0.7, 0.7);
            font-size: 14px;
        }
    }
}