@charset "utf-8";
/* CSS Document */
.contact{
    position: relative;
    background:transparent;
    font-family: sans-serif;
    &__header{
        margin-bottom: 3rem;
    }
    @media (max-width: 1100px) {
            padding: 2rem;
    }
}



.form{
    &__info{
        display:flex;
        align-items: center;
        gap: 1rem;
        font-size: 2.2vw;
        font-weight: 500;
        input{
            font-size: inherit;
            font-size: inherit;
            border: none;
            background: transparent;
            border-bottom: 1px solid #333;
            color: #777;
            &:focus{
                outline: none;
            }
        }
    }

    &__flex{
        display: flex;
        gap: 1rem;
        margin-bottom: 2rem;
        textarea{
            width: 70%;
            background: transparent;
            border: none;
            border-bottom: 1px solid #333;
            font-size: 2.2vw;
            color: #555;
            resize: none;
            &:focus{
                outline: none;
            }
        }
    }




@media (max-width: 390px) {
    .form{
        &__flex{
            flex-direction: column;
            textarea{
                font-size: 8vw;
            }
        }
        &__info{
            font-size: 8vw;
            input{
                font-size: 8vw;
            }
        }
    }
}

}

.labeltxt{
    color: aliceblue;
    font-size: .7vw;
}

@media (max-width: 390px) {
  .labeltxt{
    font-size: 3vw;
}
}

.encase{
z-index: 5;
}

.formblock{
    display: block;
    margin: 0vw;
    padding: 3vw 0 0 3vw;
    z-index: 2;
}
@media (max-width: 390px) {
  .formblock{
    padding: 0vw 0vw 0vw 13.5vw;
}
}

.para{
margin: 7.5vw 0vw 0vw 2.5vw;
display: block;
z-index: 5;
float: left;
color: aliceblue;
font-size: .8vw;
}

@media (max-width: 390px) {
.para{
  margin: 7.5vw 0vw 0vw 0vw;
  padding: 0vw 3vw 0vw 0vw;
  display: block;
  z-index: 5;
  color: aliceblue;
  font-size: 2.7vw;
  }
}

  /*dark blue front half curve*/
.formblockbg{
    height: 38vw;
    width: 38vw;
    z-index: 2;
    background: #395161;
    border-radius: 0% 50% 50% 0%;
    margin: 9vw 0vw 0vw 23.25vw;
    padding: 0vw;
    position:absolute;
}

  /*dark blue front half curve*/
@media (max-width: 390px) {
    .formblockbg {
        height: 80vw;
        width: 80vw;
        border-radius: 0% 0% 50% 50%;
        margin: 57vw 0vw 0vw 10vw;
        padding: 0vw 0vw 0vw 6vw;
        position: absolute;
    }
  } 


  /*dark blue front half square*/
.contact_square {
    height: 38vw;
    width: 30vw;
    z-index: 2;
    background: #395161;
    margin: 9vw 0vw 0vw 0vw;
    padding: 0vw;
    position: absolute;
  }

 /*dark blue front half square*/
  @media (max-width: 390px) {
    .contact_square {
      height: 49vw;
      width: 80vw;
      margin: 8vw 0vw 0vw 10vw;
      padding: 2vw 0vw 0vw 6vw;
    }
  } 




//send email button//
.eSend {
    background-color: #55758a;
    border: none;
    color: white;
    padding: .8vw 1.9vw;
    text-align: center;
    font-size: .8vw;
    margin:1.5vw 5vw 5vw 0vw;
    opacity: 0.6;
    transition: 0.3s;
    text-decoration: none;
    cursor: pointer;
  }
  
  .eSend:hover {opacity: 1}

  @media (max-width: 390px) {
  .eSend {
    background-color: #55758a;
    border: none;
    color: white;
    padding: .8vw 3vw .8vw 3vw;
    text-align: center;
    font-size: 3vw;
    margin:1.5vw 13.5vw 5vw 13.5vw;
    opacity: 0.6;
    transition: 0.3s;
    text-decoration: none;
    cursor: pointer;
  }
}


.txtarea{
  width: 40vw;
  height: 20vw;
}

  .cntcttxt {
    padding-top: 1.5vw;
  }


  /*lightblue back half curve*/

  .cntcthalfcurve {
    height: 39vw;
    width: 40vw;
    z-index: 1;
    background: #b3cadc;
    border-radius: 0% 50% 50% 0%;
    margin: 6.5vw 0vw 0vw 0vw;
    padding: 3vw 3vw 40vw 0vw;
    position: relative;
  }

 /* @media (max-width: 1600px) {
  .cntcthalfcurve {
    height: 38vw;
    width: 48vw;
    z-index: 1;
    background: #b3cadc;
    border-radius: 0% 50% 50% 0%;
    margin: 4.5% 0% 0%;
    padding: 3% 3% 45% 0%;
    position: relative;
  }
}

@media (max-width: 1386px) {
  .cntcthalfcurve {
    height: 35vw;
    width: 55vw;
    z-index: 1;
    background: #b3cadc;
    border-radius: 0% 50% 50% 0%;
    margin: 4.5% 0% 0%;
    padding: 3% 3% 55vw 0%;
    position: relative;
  }
}
*/
            /*lightblue back half curve*/
@media (max-width: 1180px) {
  .cntcthalfcurve {
    margin: 6.5vw 0vw 0vw 0vw;
  }
}
/*
@media (max-width: 1024px) {
  .cntcthalfcurve {
    height: 95%;
    width: 90vw;
    z-index: 1;
    background: #b3cadc;
    border-radius: 0% 0% 50% 50%;
    margin: 0% 0% 0% 5%;
    padding: 20% 0% 73%;
    position: relative;
  }
}

@media (max-width: 820px) {
  .cntcthalfcurve {
    height: 95%;
    width: 90%;
    z-index: 1;
    background: #b3cadc;
    border-radius: 0% 0% 50% 50%;
    margin: 0% 0% 0% 5%;
    padding: 20% 0% 73%;
    position: relative;
  }
}
*/
            /*lightblue back half curve*/
@media (max-width: 390px) {
  .cntcthalfcurve {
    height: 80vw;
    width: 90vw;
    z-index: 1;
    background: #b3cadc;
    border-radius: 0% 0% 50% 50%;
    margin: 0vw 0vw 0vw 5vw;
    padding: 90vw 0vw 0vw 73vw;
    position: relative;
  }
}




            /*lightblue back square*/
  .cntctsquare_extn {
    height: 38vw;
    width: 24vw;
    z-index: 1;
    background: #b3cadc;
    margin: 6.5vw 0vw 0vw 0vw;
    padding: 3vw 0vw 40vw 0vw;
    position: relative;
  }

            /*lightblue back square*/
 /* 
  @media (max-width: 1600px) {
    .cntctsquare_extn {
      height: 300px;
      width: 300px;
      z-index: 1;
      background: #b3cadc;
      margin: 4.5% 0% 30%;
      padding: 48% 0% 0%;
      position: relative;
    }
  }
  
  @media (max-width: 1386px) {
    .cntctsquare_extn {
      height: 30vw;
      width: 24vw;
      z-index: 1;
      background: #b3cadc;
      margin: 4.5% 0% 30%;
      padding: 48% 0% 10vw;
      position: relative;
    }
  }
  */
            /*lightblue back square*/
  @media (max-width: 1180px) {
    .cntctsquare_extn {
    margin: 6.5vw 0vw 0vw 0vw;
    }
  }
  
  /*
  @media (max-width: 1024px) {
    .cntctsquare_extn {
      height: 33vw;
      width: 90vw;
      z-index: 1;
      background: #b3cadc;
      margin: 0% 0% 0% 5%;
      padding: 0% 0% 23%;
      position: relative;
    }
  }
  
  @media (max-width: 768px) {
    .cntctsquare_extn {
      height: 33vw;
      width: 90vw;
      z-index: 1;
      background: #b3cadc;
      margin: 0% 0% 0% 5%;
      padding: 0% 0% 23%;
      position: relative;
    }
  }
  */
            /*lightblue back square*/
  @media (max-width: 390px) {
    .cntctsquare_extn {
      margin: 2vw 0vw 0vw 5vw;
      padding: 30vw 0vw 40vw 50vw;
    }
  } 