.landing{
    position: fixed;
    width: 100%;
    height: 53px;
    background: #4c5f6e;
    color: #1b222f;
    overflow: hidden;
    z-index: 5;



    .header{
        position: absolute;
        top: 1rem;
        left: 50%;
        transform: translate3d(-50%, 0, 0);
        background: #4c5f6e;

        &__wrap {
            padding: 10px;
            padding-left: 8rem;
            display: flex;
            align-items: center;
            justify-content: space-between;
            width: 95vw;
        }
        &__left {
            display: flex;
            align-items: center;
            gap: 2rem;
            & li{
                font-size: 19px;
                font-weight: 400;
                
            }
        }

        &__right {
            display: flex;
                padding-right: 2rem;
                font-size: 19px;
                font-weight: 400;
        }


        @media (max-width: 1000px){
            &__wrap {
                width: 100vw;
            }
        }

        @media (max-width: 600px){
            &__wrap {
                width: 100vw;
                padding: 2px;
            }
        }

        @media (max-width: 390px){
            &__wrap {
                width: 100vw;
                padding: 13px;
            }
        }

        @media (max-width: 600px){
            &__left {
                gap: .5rem;
            }
        }

        @media (max-width: 600px){
            &__right {
                padding-left: .5rem;
            }
        }


        @media (max-width: 1350px){
            .header {
                height: 41px;
            }
        }
    
        @media (max-width: 700px){
            .header {
                height: 131px;
            }
        }

        




    }

}

@media (max-width: 390px){
    .landing{
position: fixed;
width: 100%;
height: 45px;
background: #4c5f6e;
color: #1b222f;
overflow: hidden;
z-index: 5;
    }
}