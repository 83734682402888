@charset "utf-8";
/* CSS Document */
.contact{
    position: relative;
    background:transparent;
    font-family: sans-serif;
    &__header{
        margin-bottom: 3rem;
    }
    @media (max-width: 1100px) {
            padding: 2rem;
    }
}


.form{
    &__info{
        display:flex;
        align-items: center;
        gap: 1rem;
        font-size: 2.2vw;
        font-weight: 500;
        z-index: 50;
        input{
            font-size: inherit;
            font-size: inherit;
            border: none;
            background: transparent;
            border-bottom: 1px solid #333;
            color: #777;
            &:focus{
                outline: none;
            }
        }
    }

    &__flex{
        display: flex;
        gap: 1rem;
        margin-bottom: 2rem;
        textarea{
            width: 70%;
            background: transparent;
            border: none;
            border-bottom: 1px solid #333;
            font-size: 2.2vw;
            color: #555;
            resize: none;
            &:focus{
                outline: none;
            }
        }
    }

@media (max-width: 390px) {
    .form{
      z-index: 50;
        &__flex{
            flex-direction: column;
            textarea{
                font-size: 8vw;
            }
        }
        &__info{
            font-size: 8vw;
            input{
                font-size: 8vw;
            }
        }
    }
}
}


.labeltxt{
    color: aliceblue;
    font-size: .7vw;
}

@media (max-width: 1180px) {
  .labeltxt{
    font-size: .85vw;
}
}

@media (max-width: 1024px) {
  .labeltxt{
    font-size: 2vw;
}
}

@media (max-width: 390px) {
  .labeltxt{
    font-size: 3vw;
}
}


.messagearea{
  height: 9vw;
  width: 9vw;
}

@media (max-width: 1366px) {
  .messagearea{
    height: 7vw;
    width: 9.7vw;
    }
    }
@media (max-width: 1180px) {
  .messagearea{
    height: 7vw;
    width: 11.4vw;
  }
  }
  @media (max-width: 1024px) {
    .messagearea{
      height: 22vw;
      width: 26vw;
      z-index: 50;
    }
    }
@media (max-width: 390px) {
.messagearea{
  height: 32vw;
  width: 40vw;
  z-index: 50;
}
}

.encase{
z-index: 55;
}

.formblock{
  display: block;
  padding: 4.8vw 0 0 33vw;
  z-index: 50;
}

@media (max-width: 1180px) {
  .formblock{
    margin: 4.3vw 0vw 0vw 18vw;
    padding: 0vw 0vw 0vw 13.5vw;
}
}

@media (max-width: 1024px) {
  .formblock{
    margin: 5vw 0vw 0vw 0vw;
    padding: 16vw 20vw 0vw 20vw;
}
}

@media (max-width: 820px) {
  .formblock{
    margin: 1vw 0vw 0vw 0vw;
    padding: 19vw 20vw 0vw 20vw;
}
}

@media (max-width: 768px) {
  .formblock{
    margin: 15vw 3vw 0vw 3vw;
    padding: 2.8vw 18vw 0vw 18vw;
}
}

@media (max-width: 390px) {
  .formblock{
    margin: 25vw 0vw 0vw 0vw;
    padding: 0vw 0vw 0vw 13.5vw;
}
}

.para{
margin:5vw 2vw 0vw 7vw;
display: block;
z-index: 50;
float: left;
color: aliceblue;
font-size: .8vw;
}

@media (max-width: 1180px) {
  .para{
    margin: 5vw 0vw 0vw 7vw;
    padding: 0vw 3vw 0vw 0vw;
    font-size: .9vw;
    font-weight: 400;
    }
  }

  @media (max-width: 1024px) {
    .para{
      margin: 8vw 5vw 0vw 5vw;
      padding: 0vw 3vw 0vw 0vw;
      font-size: 1.6vw;
      font-weight: 400;
      }
    }

    @media (max-width: 820px) {
      .para{
        margin: 8vw 5vw 0vw 5vw;
        padding: 0vw 3vw 0vw 0vw;
        font-size: 1.9vw;
        font-weight: 400;
        }
      }


    @media (max-width: 768px) {
      .para{
        margin: 8vw 5vw 0vw 5vw;
        padding: 0vw 3vw 0vw 0vw;
        font-size: 1.9vw;
        font-weight: 400;
        }
      }

@media (max-width: 390px) {
.para{
  margin: 7.5vw 0vw 0vw 0vw;
  padding: 0vw 3vw 0vw 0vw;
  font-size: 3vw;
  font-weight: 400;
  }
}

//send email button//
.eSend {
  background-color: #55758a;
  border: none;
  color: white;
  padding: .7vw 2.1vw;
  text-align: center;
  font-size: .8vw;
  margin:1.1vw 5vw 5vw 1.35vw;
  opacity: 0.6;
  transition: 0.3s;
  text-decoration: none;
  cursor: pointer;
  z-index: 50;
}

.eSend:hover {opacity: 1}

@media (max-width: 1366px) {
  .eSend {
    padding: .8vw 3vw .8vw 3vw;
    margin:1.5vw 13.5vw 5vw .5vw;
}
  }

@media (max-width: 1180px) {
  .eSend {
    padding: .8vw 3vw .8vw 3vw;
    margin:2.25vw 1.5vw 5vw 1.25vw;
    font-size: 1vw;
}
  }

  @media (max-width: 1024px) {
    .eSend {
      padding: 1.25vw 5vw 1.25vw 5vw;
      margin: 1vw 5vw 10vw 5vw;
      font-size: 2.25vw;
   }
  }  

@media (max-width: 820px) {
  .eSend {
    padding: 1vw 3vw 1vw 3vw;
    margin: 1vw 7.5vw 5vw 7.5vw;
    font-size: 2vw;
 }
}

@media (max-width: 768px) {
  .eSend {
    padding: 1vw 4vw 1vw 4vw;
    margin: 1vw 6vw 5vw 6vw;
    font-size: 2vw;
 }
}

@media (max-width: 390px) {
  .eSend {
    padding: 1.5vw 6vw 1.5vw 6vw;
    margin:1vw 10vw 5vw 10vw;
    font-size: 3vw;
 }
}

.txtarea{
  width: 40vw;
  height: 20vw;
  z-index: 50;
}

.cntcttxt {
  padding-top: 1.5vw;
}

@media (max-width: 1180px) {
   .cntcttxt {
  padding-top: 1vw;
}
}

@media (max-width: 1024px) {
  .cntcttxt {
 padding-top: .5vw;
 z-index: 50;
}
}

@media (max-width: 820px) {
  .cntcttxt {
 padding-top: 2vw;
 z-index: 50;
}
}


  /*dark blue front half curve*/
.formblockbg{
    height: 38vw;
    width: 38vw;
    background: #395161;
    border-radius: 0% 50% 50% 0%;
    margin: 9vw 0vw 0vw 30vw;
    padding: 0vw;
    position:absolute;
    z-index: 1;
}

@media (max-width: 1024px) {
  .formblockbg {
      height: 80vw;
      width: 80vw;
      border-radius: 0% 0% 50% 50%;
      margin: 41vw 0vw 0vw 10vw;
      padding: 0vw 0vw 0vw 6vw;
      position: absolute;
  }
} 

@media (max-width: 820px) {
  .formblockbg {
      height: 80vw;
      width: 80vw;
      border-radius: 0% 0% 50% 50%;
      margin: 41.2vw 0vw 0vw 10vw;
      padding: 0vw 0vw 0vw 6vw;
      position: absolute;
  }
} 

@media (max-width: 390px) {
    .formblockbg {
        height: 80vw;
        width: 80vw;
        border-radius: 0% 0% 50% 50%;
        margin: 77vw 0vw 0vw 10vw;
        padding: 0vw 0vw 0vw 6vw;
        position: absolute;
        z-index: 1;
    }
  } 


  /*dark blue front half square*/
.contact_square {
    height: 38vw;
    width: 31vw;
    background: #395161;
    margin: 9vw 0vw 0vw 0vw;
    padding: 0vw;
  }

  @media (max-width: 1024px) {
    .contact_square {
      height: 46vw;
      width: 80vw;
      margin: 3.3vw 0vw 0vw 10vw;
      padding: 2vw 0vw 0vw 6vw;
    }
  } 

  @media (max-width: 820px) {
    .contact_square {
      height: 46vw;
      width: 80vw;
      margin: 6vw 0vw 0vw 10vw;
      padding: 2vw 0vw 0vw 6vw;
    }
  } 

  @media (max-width: 390px) {
    .contact_square {
      height: 86vw;
      width: 80vw;
      margin: 5vw 0vw 0vw 10vw;
      padding: 7vw 0vw 0vw 6vw;
    }
  } 


  /*lightblue back half curve*/
  .cntcthalfcurve {
    height: 38vw;
    width: 38vw;
    background: #b3cadc;
    border-radius: 0% 50% 50% 0%;
    margin: 6.5vw 0vw 0vw 0vw;
    padding: 3vw 3vw 40vw 0vw;
    position: relative;
  }

@media (max-width: 1180px) {
  .cntcthalfcurve {
    margin: 6.5vw 0vw 0vw 0vw;
  }
}

@media (max-width: 1024px) {
  .cntcthalfcurve {
    height: 81vw;
    width: 90vw;
    background: #b3cadc;
    border-radius: 0% 0% 50% 50%;
    margin: 0vw 0vw 0vw 5vw;
    padding: 0vw 0vw 0vw 0vw;
    position: relative;
  }
}

@media (max-width: 820px) {
  .cntcthalfcurve {
    height: 83.5vw;
    width: 90vw;
    background: #b3cadc;
    border-radius: 0% 0% 50% 50%;
    margin: 0vw 0vw 0vw 5vw;
    padding: 0vw 0vw 0vw 0vw;
    position: relative;
  }
}

@media (max-width: 390px) {
  .cntcthalfcurve {
    height: 80vw;
    width: 90vw;
    background: #b3cadc;
    border-radius: 0% 0% 50% 50%;
    margin: 0vw 0 0% 5%;
    padding: 20vw 0vw 0vw 73vw;
    position: relative;
  }
}

            /*lightblue back square*/
  .cntctsquare_extn {
    height: 38vw;
    width: 32.5vw;
    background: #b3cadc;
    margin: 6.5vw 0vw 0vw 0vw;
    padding: 3vw 0vw 40vw 0vw;
    position: relative;
  }

  @media (max-width: 1180px) {
    .cntctsquare_extn {
    margin: 6.5vw 0vw 0vw 0vw;
    }
  }

  @media (max-width: 1024px) {
    .cntctsquare_extn {
      height: 44vw;
      width: 90vw;
      margin: 1% 0% 0% 5%;
      padding: 0% 0% 0% 23%;

    }
  }

  @media (max-width: 820px) {
    .cntctsquare_extn {
      height: 43vw;
      width: 90vw;
      margin: 0% 0% 0% 5%;
      padding: 0% 0% 23%;

    }
  }

  @media (max-width: 390px) {
    .cntctsquare_extn {
      margin: 2vw 0vw 0vw 5vw;
      padding: 40vw 0vw 40vw 90vw;
    }
  } 